
import { JsonRpcProvider } from "ethers/providers";
import { Component } from "vue-property-decorator";
import PriceFeed from "./info/price-feed.vue";
import DevBurnList from "./info/dev-burn-list.vue";
import Base from "./Base";
import menuMixin from "./_mixin_menu";

@Component({
    mixins: [menuMixin],
    components: { PriceFeed, DevBurnList },
})
export default class Info extends Base {
    provider: JsonRpcProvider | null = null;

    mounted() {
        this.provider = this.getProvider(true);
    }
}
