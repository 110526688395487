
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class Chart extends Vue {
  coinId = "";
  loading = false;
  randId = "";

  priceChart: any = null;
  volChart: any = null;

  @Prop()
  readonly innerCoinId: string | undefined;

  reverted = false;

  toggleRevert() {
    if (this.priceChart) {
      this.reverted = !this.reverted;
      this.priceData = this.priceData.map((v) => {
        return {
          time: v.time,
          open: 1 / v.open,
          close: 1 / v.close,
          high: 1 / v.high,
          low: 1 / v.low,
        };
      });

      this.priceChart.setData(this.priceData.slice(0));
    }
  }

  priceData: {
    time: number;
    open: number;
    close: number;
    high: number;
    low: number;
  }[] = [];

  async lightweightChart() {
    try {
      const r = await fetch(
        `https://stat.eggdex.com/aggregates/candlestick-${this.coinId}-1-hour.json`
      );
      if (r.ok) {
        let json: {
          t: string;
          o: number;
          c: number;
          h: number;
          l: number;
          v: number;
        }[] = await r.json();

        json = json.reverse();

        const data = json.filter((v) => v.o && v.c && v.h && v.l);

        const priceData = data.map((v) => {
          return {
            time: Math.floor(new Date(v.t).getTime() / 1000),
            open: v.o,
            high: v.h,
            low: v.l,
            close: v.c,
          };
        });

        const histogramData = data.map((v) => {
          return {
            time: Math.floor(new Date(v.t).getTime() / 1000),
            value: v.v,
          };
        });

        const LightweightCharts = (window as any).LightweightCharts;

        const chartDiv = document.getElementById("chart_price" + this.randId);

        if (chartDiv) {
          // console.log("creating price chart", histogramData.length);
          const chart = LightweightCharts.createChart(chartDiv, {
            width: chartDiv.clientWidth,
            height: 400,
            timeScale: {
              timeVisible: true,
            },
          });

          const candleSeries = chart.addCandlestickSeries({});

          this.priceChart = candleSeries;
          this.priceData = priceData.slice(0);

          candleSeries.setData(priceData);
        }

        const volChart = document.getElementById("chart_volume" + this.randId);
        if (volChart) {
          // console.log("creating vol chart", histogramData.length);
          const chart = LightweightCharts.createChart(volChart, {
            width: volChart.clientWidth,
            height: 200,
            timeScale: {
              timeVisible: true,
            },
          });

          const histogramSeries = chart.addHistogramSeries({
            base: 0,
          });

          this.volChart = histogramSeries;

          histogramSeries.setData(histogramData);
        } else {
          console.log("VOL CHART DIV NOT FOUND!");
        }
      }
    } catch (error) {
      alert("could not load chart:" + error);
    } finally {
      this.loading = false;
    }
  }

  async mounted() {
    // wait for renders..
    this.loading = true;
    this.randId = Date.now().toString();

    if (this.innerCoinId) {
      this.coinId = this.innerCoinId;
    } else {
      this.coinId = this.$route.params.coinId;
    }

    setTimeout(() => {
      const $ = (window as any)["jQuery"];

      this.$nextTick(function () {
        // this.googleChart();
        // this.amChart();
        this.lightweightChart();
      });
    }, 2000);
  }
}
