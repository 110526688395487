
import { Vue, Component } from "vue-property-decorator";
import Big from "bignumber.js";

@Component({})
export default class DevBurnList extends Vue {
    burns = [
        {
            token: "EGG2",
            hash:
                "0x538d3d7802f32d3a30796b6749f4342d44632f6ddc3a320310a67a84ef53d223",
            amount: "350000",
        },
    ];

    partHash(h: string) {
        return h.substr(0, 10) + ".....";
    }

    toBig(v: any) {
        return new Big(v.toString());
    }
}
