
import { JsonRpcProvider } from "ethers/providers";
import { Component, Prop } from "vue-property-decorator";
import { getContract } from "../../lib/blockchain-helper";
import Chart from "./chart.vue";
import network from "../../network";
import Base from "../Base";
import staticCoins from "../static-coins";
import debounce from "lodash/debounce";
import Big from "bignumber.js";

@Component({
    components: { Chart },
})
export default class PriceFeed extends Base {
    coins = staticCoins;
    loading = true;

    chartCoinId = "";

    liquidities: {
        token: {
            id: string;
            name: string;
        };
        major: Big;
        minor: Big;
        c24: Big;
        v24: Big;
    }[] = [];

    sortLiquidities = "id";
    filterLiquidities = "";

    liquiditiesFiltered() {
        let liq = this.liquidities.slice(0);

        if (this.filterLiquidities) {
            const search = this.filterLiquidities.toUpperCase().trim();
            liq = this.liquidities.filter(
                (v) =>
                    v.token.id.includes(search) || v.token.name.includes(search)
            );
        }

        if (this.sortLiquidities) {
            liq = liq.sort((a, b) => {
                if (a.token.id > b.token.id) {
                    return 1;
                } else {
                    return -1;
                }
            });
        }
        return liq;
    }

    volumes: any = {};

    @Prop()
    readonly provider: JsonRpcProvider | undefined;

    beforeDestroy() {
        for (const coin of this.coins) {
            this.provider?.removeAllListeners(coin.exchangeAddress);
        }
    }

    showChartCoinId(id: string) {
        this.chartCoinId = "";
        this.$nextTick(function () {
            this.chartCoinId = id;
            const win = window as any;
            win.$("#charting").modal("show");
        });
    }

    async loadVolume() {
        await this.$store.dispatch("fetchVol24h");
        this.volumes = this.$store.state.vol24h;
    }

    averageAPY() {
        let a = new Big(0);
        let b = new Big(0);

        // total volume
        for (const key in this.$store.state.vol24h) {
            b = b.plus(this.$store.state.vol24h[key]);
        }

        // total liquidity
        for (const key in this.liquidities) {
            const item = this.liquidities[key];
            a = a.plus(item.major.times(2));
        }

        if (a.eq(0)) return a;

        return b.times(0.2).div(100).div(a).times(365).times(100).dp(2);
    }

    async mounted() {
        this.loadVolume();
        // watch liquidites..
        if (this.provider) {
            this.provider.pollingInterval = 1000;

            for (const coin of this.coins) {
                if (coin.exchangeAddress) {
                    const erc20 = getContract(
                        network,
                        "ERC20",
                        this.provider,
                        coin.tokenAddress
                    );

                    const watcher = debounce(async () => {
                        this.loading = true;

                        this.$nextTick(async function () {
                            let ttBalance = new Big(0);
                            let usdBalance = new Big(0);

                            if (!this.provider) return;

                            try {
                                ttBalance = this.toEther(
                                    await this.provider.getBalance(
                                        coin.exchangeAddress
                                    )
                                );
                                usdBalance = this.toEther(
                                    await erc20.balanceOf(coin.exchangeAddress),
                                    coin.decimalPlace
                                );
                            } catch (error) {
                                alert(error.message);
                            } finally {
                                this.loading = false;
                            }

                            const lIndex = this.liquidities.findIndex(
                                (v) => v.token.id == coin.id
                            );

                            let liq;

                            if (lIndex >= 0) {
                                liq = this.liquidities[lIndex];
                            } else {
                                liq = {
                                    token: {
                                        id: coin.id,
                                        name: coin.name,
                                    },
                                    major: new Big(0),
                                    minor: new Big(0),
                                    c24: new Big(0),
                                    v24: new Big(0),
                                };
                            }

                            liq.major = ttBalance;
                            liq.minor = usdBalance;

                            if (lIndex >= 0) {
                                this.liquidities.splice(lIndex, 1, liq);
                            } else {
                                this.liquidities.push(liq);
                            }
                        });
                    }, 1000).bind(this);

                    watcher();

                    this.provider.on(coin.exchangeAddress, watcher.bind(this));
                }
            }
        }
    }
}
